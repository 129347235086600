*{  
  padding: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* text-decoration: none; */
  list-style: none;
  font-family: 'Poppins', sans-serif;
  
  /* background-image: url('./assets/images/bg_wave_topdark.png'); */
  /* background-repeat: no-repeat ; */
  /* background-attachment: fixed; */
  /* background-size: 100% 100%;  */
}

.bghome {
  /* background-image: url('./assets/images/fclager2.png'); */
  /* background-image: radial-gradient(rgba(166, 3, 3, 0.36), rgba(142, 0, 0, 0.63)); */

  /* background-image: radial-gradient(rgba(166, 3, 3, 0.36), rgba(142, 0, 0, 0.87));
   */
  margin-top: 1px;   
  /* background-image: radial-gradient(rgba(160, 3, 3, 0.36), rgba(142, 0, 0, 0.71)); */
  /* background-image: radial-gradient(rgba(180, 3, 3, 0.36), rgba(120, 0, 0, 0.71)); */

   
  background-color: rgb(180, 98, 98);
  background-image: repeating-linear-gradient(0deg, rgba(255, 255, 255, 0 ), rgba(130, 0, 0, 2));

  background-position: center; 
  background-size: cover;
  /* background-attachment: fixed; */
  /* filter: grayscale(100%);  */

}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  /* background-image: linear-gradient(160deg, rgb(138, 0, 0), rgba(166, 3, 3, 0.36));  */
  background-image: linear-gradient(160deg, rgb(130, 0, 0), rgba(150, 3, 3, 0.8)); 

  /* background: #8d2f00; */
  color: rgb(255, 255, 255);
  text-transform: uppercase;

}

.navItems {
  display: flex;
  margin-right: 10px;
}

/* ? In react Link recognized as a in css */
.navItems a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 12px 15px;
  font-size: 18px; 
}

/* .active class created auto by browser. */
.navbar .active {
  text-decoration: none;
  /* background: #ffffff; */
  border-bottom: 3px solid #ff0000;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;

}

.navItems a:hover {
  text-decoration: none;
  /* background: #ffffff; */
  border-bottom: 3px solid #fff;
  border-radius: 6px;
  color: yellow;
  /* font-weight: bold; */
  font-size: 18px;
}

.icons {
  display: none;
  margin-top: 20px;
}

.icons i {
  font-size:3rem ;
}

.pricebox {
  box-shadow: 4px 4px 15px #acacac;
}

.contactbox {
  box-shadow: 4px 4px 15px #acacac;
}

.heroimg {
  opacity: 0.8;
  width: 100%; 
  height: auto;
  box-shadow: 8px 8px 10px #ce6767;
  border-radius: 5px;
  margin-top: 2em;
  margin-bottom: 3em;
}

.heroheader1 {
  font-size: 5rem;
}

.heroheader2 {
  font-size: 3rem;
}

.heroheader3 {
  font-size: 2rem;
}


footer {
  /* background-image: linear-gradient(90deg, rgb(138, 0, 0), rgba(166, 3, 3, 0.36)); */

  margin-top: 1px;
  /* background-image: linear-gradient(260deg, rgb(138, 0, 0), rgba(166, 3, 3, 0.36)); */
  background-image: linear-gradient(660deg, rgb(130, 0, 0), rgba(150, 3, 3, 0.9)); 
}

.footernav {
  margin-top: 1em;
  color: #fff; 
  font-size: 16px;
}

.footernav a {
  text-decoration: none; 
  color: #fff; 
  font-size: 18px;
  padding: 5px ;
}

.footernav a:visited {
  color: #fff;  
}

.footernav a:hover{ 
  border-bottom: 3px solid yellow;
  border-radius: 6px;
  color: yellow;
  /* font-weight: bold; */
}

.footernav .active {
  text-decoration: none;
  /* background: #ffffff; */
  border-bottom: 3px solid #ff0000;
  border-radius: 6px;
  color: #fff;
  /* font-weight: bold; */
  font-size: 18px;
}


@media screen and (max-width: 700px) {
  .navItems a {
    display: none;    
  }

  .icons {
    display: block;
  }
}

@media screen and (max-width: 700px) {
  .movItems {
    display: flex;
    flex-direction: column;
    align-items: center;   
  }

  .movItems a {
    display: block;
  }

  nav .navbar {
    display: block;
  }

  .logo {
    position: relative;
  }

  .logo h1 {
    margin: 0;
    padding: 0;
  }

  .icons {
    position: absolute;
    top: 12px;
    right:12px;
    font-size: 24px;
  }
}